import {EventEmitter, Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from "rxjs";
import {ApplicationState} from "@src/app/model/applicationstate";
import {map, take, takeUntil, withLatestFrom} from "rxjs/operators";
import {LoggingService} from "@src/app/services/logging/logging.service";

@Injectable({
  providedIn: 'root'
})
export class ApplicationStateService implements OnDestroy {
  private _debug = (n: string) => this._loggingService.debug(n);

  private _serviceDestroyed$: Subject<void> = new Subject();
  private _appState$: Subject<ApplicationState> = new BehaviorSubject(ApplicationState.init);

  private _defaultProgress = {cur: 0, expect: 100};
  private _stateProgress$: Subject<{ cur: number, expect: number }> = new BehaviorSubject(this._defaultProgress);

  // general spinner values; actual spinner component is spun up in home component
  // if you have a process that takes a long time, use this spinner by calling activateMiscSpinner
  public readonly generalSpinnerFinished$: EventEmitter<void> = new EventEmitter<void>();
  public readonly showGeneralSpinner$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public readonly generalSpinnerText: string = 'app.spinner.misc';

  constructor(
    private _loggingService: LoggingService,
  ) {
    // TODO: debug subscription, remove once the startup progress is introduced
    this._stateProgress$.pipe(
      takeUntil(this._serviceDestroyed$),
      withLatestFrom(this._appState$)
    ).subscribe(([p, s]) => {
      this._logState(s, p);
    });
  }

  /**
   * Function that handles activation of the general spinner.
   * The indicator$ is an observable that should emit when the spinner should be deactivated.
   * The spinner will be deactivated when the indicator$ emits or when an error occurs.
   */
  public activateGeneralSpinner(indicator$: Observable<any>): void {
    this.showGeneralSpinner$.next(true);
    indicator$.pipe(
      takeUntil(this._serviceDestroyed$),
      take(1)
    ).subscribe({
      next: _ => this.deactivateGeneralSpinner(),
      error: _ => this.deactivateGeneralSpinner()
    });
  }

  public deactivateGeneralSpinner(): void {
    this.generalSpinnerFinished$.emit();
    this.showGeneralSpinner$.next(false);
  }

  /**
   * changes the state to the given one and resets the progress.
   *
   * @param newState The new state of the application
   */
  public updateState(newState: ApplicationState): void {
    this._appState$.next(newState);
    this._stateProgress$.next(this._defaultProgress);
  }

  /**
   * Update (overwrite) the state progress.
   * The expected number can be passed to set a other target than 100.
   *
   * @param newStateNum New number value of state corresponding to the enum value
   * @param expect The max expected state of the application
   */
  public updateProgress(newStateNum: number, expect: number = this._defaultProgress.expect): void {
    this._checkAndUpdateProgress({cur: newStateNum, expect});
  }

  /**
   * Helper to check and update the progress
   */
  private _checkAndUpdateProgress(p: { cur: number, expect: number }) {
    if (p.cur >= 0 && p.cur <= p.expect) {
      this._stateProgress$.next(p);
    }
  }

  /**
   * Increment the state progress.
   * The expected number can be passed to set a different target than 100.
   *
   * @param inc How much to increment the state by (see enum for values)
   * @param expect The max expected state of the application
   */
  public incrementProgress(inc: number, expect: number = this._defaultProgress.expect): void {
    this._stateProgress$.pipe(
      takeUntil(this._serviceDestroyed$),
      take(1)
    ).subscribe(cur => {
      const nw = cur.cur + inc;
      this._checkAndUpdateProgress({cur: nw, expect});
    });
  }

  /**
   * Return the state observable.
   */
  public getState$(): Observable<ApplicationState> {
    return this._appState$;
  }

  /**
   * Returns the next state the application will be in (i.e. what it is processing atm)
   */
  public getNextState$(): Observable<ApplicationState> {
    return this._appState$.pipe(map(t => t + 1));
  }

  /**
   * Return the progress observable.
   */
  public getProgress$(): Observable<{ cur: number, expect: number }> {
    return this._stateProgress$;
  }

  /**
   * Progress to percentage helper.
   */
  public static progressToPercentage(progress: { cur: number, expect: number }): number {
    if (progress.expect > 0) {
      return Math.ceil((100 * progress.cur) / progress.expect);
    } else {
      return 100;
    }
  }

  /**
   * State debug function.
   */
  private _logState(state: ApplicationState, progress: { cur: number, expect: number }) {
    const p = ApplicationStateService.progressToPercentage(progress);
    switch (state) {
      case ApplicationState.init:
        this._debug("ApplicationStateService::_appState$ (" + ("000" + p).slice(-3) + "): init (" + state + ")");
        break;
      case ApplicationState.plainConfigLoaded:
        this._debug("ApplicationStateService::_appState$ (" + ("000" + p).slice(-3) + "): plainConfigLoaded  (" + state + ")");
        break;
      case ApplicationState.materialsAndTexturesLoaded:
        this._debug("ApplicationStateService::_appState$ (" + ("000" + p).slice(-3) + "): materialsAndTexturesLoaded  (" + state + ")");
        break;
      case ApplicationState.currentBlueprintsLoaded:
        this._debug("ApplicationStateService::_appState$ (" + ("000" + p).slice(-3) + "): currentBlueprintsLoaded (" + state + ")");
        break;
      case ApplicationState.catalogAndVariantsLoaded:
        this._debug("ApplicationStateService::_appState$ (" + ("000" + p).slice(-3) + "): catalogAndVariantsLoaded (" + state + ")");
        break;
      case ApplicationState.configFatVariantsLoaded:
        this._debug("ApplicationStateService::_appState$ (" + ("000" + p).slice(-3) + "): configFatVariantsLoaded (" + state + ")");
        break;
      case ApplicationState.configAssetsLoaded:
        this._debug("ApplicationStateService::_appState$ (" + ("000" + p).slice(-3) + "): configAssetsLoaded (" + state + ")");
        break;
      case ApplicationState.rulesAreLoaded:
        this._debug("ApplicationStateService::_appState$ (" + ("000" + p).slice(-3) + "): rulesAreLoaded (" + state + ")");
        break;
      case ApplicationState.currentScopeAndRetailerAreLoaded:
        this._debug("ApplicationStateService::_appState$ (" + ("000" + p).slice(-3) + "): currentScopeAndRetailerAreLoaded (" + state + ")");
        break;
      case ApplicationState.translationsAreLoaded:
        this._debug("ApplicationStateService::_appState$ (" + ("000" + p).slice(-3) + "): translationsAreLoaded (" + state + ")");
        break;
      case ApplicationState.appInitialized:
        this._debug("ApplicationStateService::_appState$ (" + ("000" + p).slice(-3) + "): appInitialized (" + state + ")");
        break;
      case ApplicationState.fullyOperational:
        this._debug("ApplicationStateService::_appState$ (" + ("000" + p).slice(-3) + "): fullyOperational (" + state + ")");
        break;
      default:
        this._debug("ApplicationStateService::_appState$: unknown state (s: " + state + ", p:" + p + ")");
        break;
    }
  }

  /**
   * On destroy close subscriptions
   */
  public ngOnDestroy(): void {
    this._serviceDestroyed$.next();
    this._serviceDestroyed$.complete();
  }
}
