import {Component} from '@angular/core';
import {InteractionService} from "@src/app/services/interaction/interaction.service";
import {ViewMode} from "@src/app/model/view-mode";
import {ApplicationStateService} from "@src/app/services/applicationstate/application-state.service";
import {ApplicationState} from "@src/app/model/applicationstate";
import {ActivatedRoute, Router} from "@angular/router";
import {NotificationService} from "@src/app/services/notification/notification.service";
import {PlacementService} from "@src/app/services/placement/placement.service";
import {MeasurementService} from "@src/app/services/measurement/measurement.service";
import {InitDataService} from "@src/app/services/data/init-data.service";
import {ModalService} from "@src/app/services/modal/modal.service";
import {LoggingService} from "@src/app/services/logging/logging.service";
import {timer} from "rxjs";

@Component({
  selector: 'app-screenshot',
  templateUrl: './screenshot.component.html',
  styleUrls: ['./screenshot.component.scss']
})
export class ScreenshotComponent {

  constructor(
    private _interactionService: InteractionService,
    private _applicationStateService: ApplicationStateService,
    private _notificationService: NotificationService,
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _measurementService: MeasurementService,
    private _dataService: InitDataService,
    private _modalService: ModalService,
    private _placementService: PlacementService,
    private _loggingService: LoggingService
  ) {
    // Disable all notifications as first action.
    this._notificationService.disableAllNotifications(true);
    this._modalService.disabled = true;

    // Now subscribe to route parameters and check if status is desired
    this._activatedRoute.params.subscribe(params => {
      // > 1 is for screenshots
      if (params?.mode && +ViewMode[params.mode] > 1) {
        // Listen to the applicationStateService and change viewMode when applicable
        this._applicationStateService.getState$().subscribe(state => {
          switch (state) {
            case ApplicationState.appInitialized:
              _placementService.showMarkers = _activatedRoute.snapshot.queryParams.markers === 'true';

              this._interactionService.setViewMode(+ViewMode[params.mode] as ViewMode).then(() => {
                // Create the measurement arrows first so they are taken into account when fitting the camera
                this._measurementService.setMeasurementArrows(this._dataService.forceMeasurementsOn);
                this._interactionService.fitCameraToConfiguration();

                // Update measurement arrows in case the camera has rotated
                this._measurementService.setMeasurementArrows(this._dataService.forceMeasurementsOn);
                // Wait a little bit before taking the screenshot to give background processes time to finish (prevents white screenshots)
                timer(500).subscribe(() => this._loggingService.debug(`ScreenshotComponent:: Ready for screenshot!`));
              });
              break;
            default:
              break;
          }
        });
      } else {
        // Otherwise go back
        this._router.navigate([".."]);
      }
    });
  }
}
